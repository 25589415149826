import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Grid, Container, Typography, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { WaveUpSVG } from '../../components/WaveSVGs/WaveUpSVG';
import { FC } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    paddingBottom: '2rem',
    position: 'relative',
    zIndex: 4,
    // margin: '0 0 6rem',
    // paddingBottom: '10rem',
    // [theme.breakpoints.down('lg')]: {
    //   margin: '0 0 5rem',
    // },
    // [theme.breakpoints.down('md')]: {
    //   margin: '0 0 3rem',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   margin: '0 0 1rem',
    // },
  },
  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
      marginTop: '1rem',
    },
  },
  awardBlue: {
    color: '#2A7ABC',
  },
  br: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  awardsSection: {
    position: 'relative',
    marginTop: '0',
    background: '#fff',
    paddingBottom: '0px',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  flexCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  whiteBox: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: '2px solid #E5EAF4',
    padding: '20px',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '176px',
    height: '176px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      marginBottom: '20px',
    },
  },
}));

const Awards: FC = () => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width: 960px)');
  return (
    <>
      <div className={classes.root}>
        <WaveUpSVG height="213" width="100%" fill="#fff" shadow />
        <Container>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xs={12} md={10}>
              <Typography variant="h2" className={classes.header}>
                WorkWavers are proud <br className={classes.br} />
                to be part of an <span style={{ color: '#055291' }}>Award Winning Culture</span>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container className={classes.awardsSection}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item xs={6} md={2} className={classes.flexCont}>
            <div className={classes.whiteBox}>
              <StaticImage
                src="../../images/awards/inc-power-partner-logo.png"
                alt="Inc. Power Partner 2023"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={2} className={classes.flexCont}>
            <div className={classes.whiteBox}>
              <StaticImage
                src="../../images/awards/the-software-report-top-100-2024.png"
                alt="Top 100 Software Companies 2024"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={2} className={classes.flexCont}>
            <div className={classes.whiteBox}>
              <StaticImage
                src="../../images/awards/cloud-awards-shortlist-23.png"
                alt="The CLOUD Awards Shortlist 2023-24"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={2} className={classes.flexCont}>
            <div className={classes.whiteBox}>
              <StaticImage
                src="../../images/awards/saas-awards-shortlist-22.png"
                alt="The SaaS Awards Shortlist 2022"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={2} className={classes.flexCont}>
            <div className={classes.whiteBox}>
              <StaticImage
                src="../../images/awards/best-places-to-work-nj.png"
                alt="NJ Best Places to Work 2023"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Awards;
